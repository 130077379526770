@font-face {
  font-family: 'futura_md_btmedium';
  src: url('../public/assets/css/futumd-webfont.woff2') format('woff2'),
    url('../public/assets/css/futumd-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*Global Css*/
html {
  font-size: 16px;
}
body {
  font-family: 'futura_md_btmedium';
  /* color: #444444; */
  /* background: #EBF7FF; */
}

a {
  color: #00a6d6;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

/*Global Css*/

.lefmainhome {
  /* background: #3D52B9 !important; */
  border-top-right-radius: 30px;
  /* border-bottom-right-radius: 30px; */
  position: relative;
  z-index: 9;
  position: fixed;
  left: 0;
  height: 100%;
  padding: 50px 0;
}

.lefmainhome .logo {
  text-align: center;
  padding: 0px 12px 25px 12px;
}

.lefmainhome .logo .logo__item.cursor-pointer:hover {
  cursor: pointer;
}

/* .lefmainhome .logo .logo__item:not(:last-child):after { */
.lefmainhome .logo .logo__item__divider {
  /* content: ''; */
  /* position: absolute; */
  /* right: -2%; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  display: flex;
  width: 2px;
  height: 19px;
  background: #C3E6FF;
}
.lefmainhome .logo .logo__item--company.hover:hover {
  cursor: pointer;
}
.lefmainhome .logo .logo__item--company img {
  max-width: 125px;
}

@media screen and (min-width: 1200px) {
  .lefmainhome .logo .logo__item--company img {
    max-width: 175px;
  }
}

@media screen and (min-width: 1440px) {
  .lefmainhome .logo .logo__item--company img {
    max-width: 100%;
  }
}


.lefmainhome li {
  margin-bottom: 5px;
  /* width: 100%; */
  white-space: nowrap;
}

.lefmainhome ul {
  padding-left: 5px;
}

.lefmainhome li a {
  font-style: 500;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  padding: 13px 20px;
  border: none !important;
  color: #fff !important;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .lefmainhome li a svg {
    margin-right: 0 !important;
  }
  .lefmainhome li a span {
    display: none;
  }
}

@media screen and (min-width: 1200px){
  .lefmainhome li a {
    font-size: 16px;
    padding: 13px 15px;
  }
}

@media screen and (min-width: 1400px){
  .lefmainhome li a {
    font-size: 19px;
    padding: 10px 20px;
  }
}

.lefmainhome li a svg {
  margin-right: 18px;
}

/* .lefmainhome li a:hover, .lefmainhome li a.active {
  background: #FAFDFF !important;
  color: #3D52B9 !important;
} */

/* .lefmainhome li a:hover, .lefmainhome li a:hover svg path {
  fill: #3D52B9;
}

.lefmainhome li a:hover, .lefmainhome li a svg path {
  fill: #fff;
}

.lefmainhome li a:hover, .lefmainhome li a.active svg path {
  fill: #3D52B9;
} */

.nav-tabs .nav-link {
  /* border-right: 0px; */
  border-radius: 16px;
  transition: .5s all ease;
}

.nav-tabs {
  border: none;
}

.dashboardright {
  /* background: #EBF7FF; */
  /* padding-right: 30px; */
  padding-left: 16.66666667%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 100%;
  padding: 0;
}

.dashboardright .header {
  padding: 32px 0 0 36px;
}

.dashboardright .header h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
  color: #3D52B9;
  margin: 0;
}
.dashboardright .header .counselor h2 {
  font-size: 22px;
  /* color: #0A2145; */
}

/* .dashboardright .header .username {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgb(94 19 57 / 7%);
  border-radius: 31px;
  width: 200px;
  height: 55px;
  color: #3D52B9;
  padding: 10px 0 10px 20px;
  cursor: pointer;
}

.dashboardright .header .username:after {
  display: none;
}

.dashboardright .header .username img {
  border-radius: 50px;
}

.dashboardright .header .username span {
  color: #3D52B9;
  padding-left: 10px;
}

.dashboardright .header .username .arr0w {
  float: right;
  margin-right: 20px;
}

.dashboardright .header .username .arr0w img {
  border-radius: 0;
} */

.dashboardright .header .headerright {
  justify-content: flex-end;
  display: flex;
}

.dashboardright .header .notification {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgb(94 19 57 / 7%);
  border-radius: 13px;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  margin: 0 25px 0 30px;
}

.dashboardright .header .meddage {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgb(94 19 57 / 7%);
  border-radius: 13px;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
}

/* .salesleadinner .leftsection {
  padding-left: 40px;
} */


.dashboard-applicants-score-yield-rate-title {
  padding-bottom: 15px;
}

.deposits-count{
  font-size: 35px;
  font-weight: 500;
  margin: 0;
  line-height: 3;
}

.deposits-count-title {
  margin-top: 40px;
}

.salerepcount ul {
  padding: 0;
  margin: 0 -16px;
  list-style: none;
}

.salerepcount ul li {
  background: rgba(50, 171, 227, 0.2);
  box-shadow: 0px 4px 13px rgb(69 97 190 / 10%);
  border-radius: 53px;
  padding: 10px 15px;
  flex: 1;
  /* margin-top: 15px; */
}

.salerepcount ul li:not(:last-child) {
  margin-right: 20px;
}

.salerepcount ul li .icon {
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  line-height: 34px;
  text-align: center;
  vertical-align: text-top;
}

.salerepcount ul li .name {
  color: #2795C8;
  font-size: 22px;
  padding: 0 15px;
  vertical-align: text-top;
  display: inline-block;
  white-space: nowrap;
}

.salerepcount ul li span.count {
  font-size: 18px;
  background: rgba(39, 149, 200, 0.5);
  border-radius: 43px;
  width: 50px;
  height: 50px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  float: right;
  margin-left: auto;
}

.boxsizecomon {
  /* background: #FFFFFF; */
  /* border: 2px solid #C4E6FF; */
  /* box-shadow: 0px 5px 17px rgba(0, 28, 125, 0.2); */
  border-radius: 30px;
}

.boxsizecomon h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  /* color: #3D52B9; */
  text-align: left;
  padding-left: 20px;
  margin-bottom: 12px;
}

.boxsizecomon h3 {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  /* color: #3D52B9; */
  text-align: left;
  margin-bottom: 12px;
}

.newapplicant {
  text-align: center;
  padding: 20px 10px 10px 10px;
}

.newapplicant img {
  max-width: 100%;
}

.salesleadinner .salerepcount {
  padding-left: 40px;
}

.salesleadinner .rightsection {
  padding-left: 30px;
}

.salesleadinner {
  /* padding-top: 20px; */
  padding-bottom: 40px;
}

.todayleadsection {
  padding-left: 40px;
  margin-top: 40px;
  color: #878787;
}

.todayleadsection .innersection {
  background: #FEFDFF;
  box-shadow: 0px 5px 17px rgb(0 28 125 / 20%);
  border-radius: 25px;
  padding: 20px 30px;
  margin-bottom: 60px;
}

.todayleadsection .headsection h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  color: #2795C8;
  float: left;
}

.todayleadsection .headsection {
  clear: both;
  overflow: hidden;
  margin-bottom: 30px;
}

.todayleadsection .headsection h3 img {
  vertical-align: text-top;
  margin-right: 15px;
}

.todayleadsection .headsection h3 span {
  display: inline-block;
  padding-top: 3px;
  vertical-align: text-top;
}

.todayleadsection .headsection .leadcount {
  display: inline-block;
  width: 20%;
  float: right;
  text-align: left;
  color: #2795C8;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
}

.clr {
  clear: both;
}

.todayleadsection .listing {
  background: #FEFDFF;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 25%);
  border-radius: 25px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 30px;
  border: solid 3px transparent;
  transition: .5s all ease;
}

 .todayleadsection .listing.active {
  background: #FEFDFF;
  border: 3px solid #4C99DF;
  box-sizing: border-box;
  box-shadow: 0px 5px 16px rgba(0, 28, 125, 0.2);
  border-radius: 25px;
  position: relative;
}

.todayleadsection .listing.active::before {
  content: '';
  position: absolute;
  right: -58px;
  top: 50%;
  transform: translateY(-50%);
  width: 58px;
  height: 3px;
  background: #4C99DF;
}

.todayleadsection .listing.active::after {
  /* content: ''; */
  position: absolute;
  right: -64px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background: url('./assets/images/Completed_actors-bullet.png') no-repeat center 0px;
}

.todayleadsection .listing .userpic {
  float: left;
}

.todayleadsection .listing .userpic img {
  width: 90%;
}

.todayleadsection .listing .userinfo {
  float: left;
  /* padding-left: 10px; */
  width: 71%;
}

.todayleadsection .listing .userinfo .name {
  color: #4C99DF;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 8px;
}

.todayleadsection .listing .userinfo span {
  display: block;
}

.todayleadsection .listing .userinfo i {
  margin-right: 5px;
  color: #4C99DF;
}

.todayleadsection .listing .userinfo .call, .todayleadsection .listing .userinfo .email {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #878787;
}

.todayleadsection .progresbar .lastcontact {
  font-size: 14px;
  color: #878787;
}

.todayleadsection .progresbar .barsection .totalcount {
  display: block;
  text-align: center;
  color: #4C99DF;
  font-size: 17px;
  line-height: normal;
}

.todayleadsection .progresbar .barsection .bar {
  width: 100%;
  display: block;
  background: #E5E5E5;
  border-radius: 20px;
  height: 5px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.todayleadsection .progresbar .barsection .percentage {
  background: #4C99DF;
  border-radius: 20px;
  display: block;
  line-height: normal;
  height: 5px;
  font-size: 0px;
}

.totalpercentage {
  background: #4C99DF;
  /* color: #4C99DF; */
  /* border-radius: 13px; */
  /* font-weight: 400; */
  font-size: 16px;
  text-align: center;
  color: #fff;
  /* height: 74px; */
  /* line-height: 74px; */
  /* width: 70%; */
  /* float: right; */
}

.todayleadsection .leadcontent {
  width: 86%;
  float: right;
  margin-top: 0;
  position: relative;
}

.todayleadsection .leadcontent {
  max-width: 365px;
  width: 100%;
  position: absolute;
  right: -453.5px;
  top: 0;
}

.todayleadsection .leadcontent .box {
  background: #FEFDFF;
  box-shadow: 0px 5px 17px rgba(0, 28, 125, 0.2);
  border-radius: 25px;
  padding: 20px 10px 10px 20px;
}

.todayleadsection .leadcontent .box h2 {
  color: #2795C8;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 20px;
}

:root {
  --listboxUlBeforeHeightOpened: 0;
  --listboxUlBeforeHeightCompleted: 0;
  --leadContentBeforeHeight: 0;
  --leadContentBeforeTop: 0;
}


.todayleadsection .leadcontent::before {
  content: '';
  position: absolute;
  left: -33px;
  top: var(--leadContentBeforeTop);
  height: var(--leadContentBeforeHeight);
  width: 3px;
  background: #4C99DF;
}

.todayleadsection .listbox {
  position: relative;
}

.todayleadsection .listbox::before {
  content: '';
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  width: 42px;
  height: 3px;
  background: #4C99DF;
}

.todayleadsection .listbox::after {
  content: '';
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background: url('./assets/images/Completed_actors-bullet.png') no-repeat left 0px;
}

.todayleadsection .listbox ul {
  list-style: none;
  padding-left: 10px;
  position: relative;
}

.todayleadsection .listbox ul.Opened::before {
  height: var(--listboxUlBeforeHeightOpened);
}
.todayleadsection .listbox ul.Completed::before {
  height: var(--listboxUlBeforeHeightCompleted);
}

.todayleadsection .listbox ul::before {
  content: '';
  position: absolute;
  left: -8px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 3px;
  /* height: calc(100% - 24px); */

  background: #4C99DF;
}

.todayleadsection .listbox li::marker {
  display: none;
}

.todayleadsection .listbox li:last-child {
  margin-bottom: 0 !important;
}

.todayleadsection .listbox li {
  /* background: url('./assets/images/Completed_actors-bullet.png') no-repeat left 9px; */
  padding-left: 15px;
  padding-right: 15px;
  color: #2795C8;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.todayleadsection .listbox li .factor {
  font-size: 15px;
  line-height: normal;
}

.todayleadsection .listbox li::before {
  content: '';
  position: absolute;
  left: -18px;
  top: 50%;
  /* top: 50%; */
  transform: translateY(-50%);
  width: 20px;
  height: 3px;
  background: #4C99DF;
}


.todayleadsection .listbox li::after {
  content: '';
  background: url('./assets/images/Completed_actors-bullet.png') no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 14px;
  height: 14px;
}

/* .todayleadsection .openfactors {
  margin-top: 90px;
} */

img {
  max-width: 100%;
  ;
}


@media screen and (max-width: 1024px) { 
  .dashboard-stats {
    flex-wrap: wrap;
  }
  .dashboard-stats > li {
    width: 40%;
  }
  .dashboard-stats ul li:nth-child(even):after {
    display: none;
  }
}

/* tri-toggler */
.tri-toggler {
  list-style-type: none;
  margin: 0 20px 0 0;
  padding: 0;
  font-size: smaller;
  text-align: center;
}

.tri-toggler li {
  float: center;
  margin: 0 5px 0 0;
  margin: 0;
  width: 108px;
  height: 40px;
  position: relative;
}

.tri-toggler label,
.tri-toggler input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tri-toggler input[type="radio"] {
  opacity: 0;
  z-index: 100;
}

.tri-toggler input[type="radio"]:checked+label,
.Checked+label {
  background: rgb(61, 82, 185);
}

.tri-toggler label {
  padding: 10px;
  /* border: 1px solid #CCC; */
  border-radius: 10px;
  cursor: pointer;
  z-index: 90;
}

/* .tri-toggler label:hover {
  background: red;
  color: blue;
} */

.deposits-count .highcharts-axis-labels.highcharts-yaxis-labels{
  display:none;   
}